// @ts-check
import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "../components/typography"

const Wrapper = styled.div`
  padding: 100px 40px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 150px;
`

const NotFoundPage = () => (
  <Layout newsletter={null}>
    <SEO title="Not Found" />
    <Wrapper>
      <Typography variant="h1" as="h1">
        404 - Not found
      </Typography>

      <Typography variant="h4" as="p">
        The page you are trying to access does not exist, and may have never
        existed.
      </Typography>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
